.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 245px);
}

.notfound h1 {
  /* font-size: 60px; */
  font-size: 15em;
  font-weight: 100;
}
